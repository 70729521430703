import * as Sentry from "@sentry/react";

import { environment } from './client/config/environment';
import { EnvironmentName } from "./client/constants/Environment";


const isProduction: boolean = [EnvironmentName.PRODUCTION, EnvironmentName.CANARY].includes(environment.Name)

Sentry.init({
  dsn: environment.SENTRY_DSN,
  environment: BUILD_ENV,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: isProduction ? 0.01 : 1,
  // Session Replay
  replaysSessionSampleRate: isProduction ? 0.01 : 1, 
  replaysOnErrorSampleRate: isProduction ? 0.01 : 1, 
});
